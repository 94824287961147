import React from "react";
import styles from "./LandingPage.module.css";
import { Button } from "../shared";
import { ReactComponent as LogoImage } from "./static/logo.svg";
import { ReactComponent as UUNNLogoImage } from "./static/uunn_logo.svg";
import LensImage from "./static/lens.gif";

export const LandingPage = () => (
  <div className={styles.root}>
    <div className={styles.header}>
      <LogoImage />
    </div>
    <div className={styles.blueBackground} />
    <div className={styles.content}>
      <UUNNLogoImage />
      <img src={LensImage} alt="alt" className={styles.lens} />
      <h1>Creators of uunn, the world’s first health tracker for your teeth.</h1>
      <p>Your phone. Three photos. Total plaque visibility.</p>
      <p>We’ve spent the last four years bringing the best brains together to build a product that you and your teeth will love. See for yourself...</p>
      <Button
        color="blue"
        title="Visit uunn.co.uk"
        to="https://www.uunn.co.uk"
        newWindow
      />
    </div>
  </div>
);
