import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { LandingPage } from "./app/landingPage/LandingPage";

/**
 * Fix for scrolling to the top on route change.
 * Will only scroll to top when new route is not an anchor (e.g. /#subscribe).
 * https://github.com/ReactTraining/react-router/issues/2019#issuecomment-292711226
 */
const ScrollToTop = () => {
  if (!window.location.hash) {
    window.scrollTo(0, 0);
  }
  return null;
};

const App = () => {
  return (
    <BrowserRouter>
      <Route component={ScrollToTop} />
      <Switch>
        <Route component={LandingPage} />
      </Switch>
    </BrowserRouter>
  );
};

export default App;
