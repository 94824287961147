import React from "react";
import { Link as ReactLink } from "react-router-dom";
import PropTypes from "prop-types";
import styles from "./Button.module.css";
import { animateOnAnchorClick } from "./utils";

/**
 * Using <a /> elements for anchors because <ReactLink /> does not seem to work.
 */
const isLinkInternal = (to) => {
  if (to.startsWith("/") && !to.startsWith("/#")) {
    return true;
  }
  return false;
};

const Link = (props) => {
  if (isLinkInternal(props.to)) {
    return (
      <ReactLink
        to={props.to}
        onClick={props.onClick}
        className={styles.defaultLinksDecoration}
      >
        {props.children}
      </ReactLink>
    );
  } else {
    return (
      <a
        href={props.to}
        target={props.newWindow ? "_blank" : "_self"}
        rel={props.newWindow ? "noopener noreferrer" : ""}
        onClick={(e) => props.animateAnchorClick ?
          animateOnAnchorClick(e) && props.onClick && props.onClick(e)
          : props.onClick && props.onClick(e)
        }
        className={styles.defaultLinksDecoration}
      >
        {props.children}
      </a>
    );
  }
};

const DefaultContainer = (props) => {
  if (props.disableOnClickEventInline) {
    return (
      <div onClick={props.onClick}>
        {props.children}
      </div>
    );
  } else {
    return (
      <div onClick={() => !props.disabled && props.onClick && props.onClick()}>
        {props.children}
      </div>
    );
  }
};

const ButtonContainer = (props) => {
  if (props.to) {
    return <Link {...props}>{props.children}</Link>;
  } else {
    return <DefaultContainer {...props}>{props.children}</DefaultContainer>;
  }
};

const Button = (props) => {
  const { title, color, className, disabled, fullWidth } = props;
  const Icon = props.icon;
  let classes = `${styles.button} ${styles[color]}`;

  if (className) {
    classes += ` ${className}`;
  }
  if (disabled) {
    classes += ` ${styles.disabled}`;
  }
  if (Icon) {
    classes += ` ${styles.short}`;
  }
  if (fullWidth) {
    classes += ` ${styles.fullWidth}`;
  }

  return (
    <ButtonContainer {...props}>
      <div className={`${classes}`}>
        <div className={styles.container}>
          {Icon && <Icon />}
          <span>{title}</span>
        </div>
      </div>
    </ButtonContainer>
  );
};

Button.propTypes = {
  animateAnchorClick: PropTypes.bool,
  className: PropTypes.string,
  color: PropTypes.string,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  icon: PropTypes.object,
  onClick: PropTypes.func,
  title: PropTypes.string,
  to: PropTypes.string,
};

Button.defaultProps = {
  color: "blue",
};

export default Button;
